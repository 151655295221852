import { LANG_DEFAULT, LANGUAGES, LANGUAGES_ACTIVE_LIST } from '@/base/const/lang.const'
import i18n, { loadLanguage } from '@/base/i18n/index'
import { CookieClass } from '@/base/class/cookie.class'
import { COOKIE_LANG } from '@/base/const/cookies.const'
import { Raven } from 'vue-raven'

const state = {
  current: LANG_DEFAULT
}
const mutations = {
  SET_CURRENT_LANGUAGE (state, value) {
    state.current = value
  }
}
const actions = {
  setLanguage ({ commit }, value) {
    CookieClass.setCookie(COOKIE_LANG, value)
    loadLanguage(value.toLowerCase())
      .then(() => {
        commit('SET_CURRENT_LANGUAGE', value)
        i18n.locale = value.toLowerCase()
      })
      .catch((error) => {
        Raven.captureMessage('loadLanguage action error in lang.js', {
          extra: { error }
        })
    })
  }
}
const getters = {
  getCurrentLanguage (state, getters) {
    const languagesList = getters.getAvailableLanguagesList
    if (!state.current || !languagesList.includes(state.current)) {
      return LANG_DEFAULT
    }
    return state.current
  },
  getAvailableLanguagesList (state, getters, rootState) {
    const paymentData = rootState.payment.paymentData
    const confirmationData = rootState.confirmation.data
    if (paymentData) {
      const languagesAvailable = paymentData.languageData?.languagesAvailable || []
      return LANGUAGES_ACTIVE_LIST.filter(x => languagesAvailable.includes(x))
    } else if (confirmationData) {
      const languagesAvailable = confirmationData.languageData?.languagesAvailable || []
      return LANGUAGES_ACTIVE_LIST.filter(x => languagesAvailable.includes(x))
    }
    return LANGUAGES_ACTIVE_LIST
  },
  isDefaultLangActive (state) {
    return state.current === LANG_DEFAULT
  },
  isLangForYourCxActive (state) {
    return [LANGUAGES.pl, LANGUAGES.de].includes(state.current)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
