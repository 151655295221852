<template>
  <div id="yourcx.insert" class="yourcx-survey"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'YourCxSurvey',
  computed: {
    ...mapGetters({
      currentLanguage: 'getCurrentLanguage'
    })
  },
  mounted () {
    if (!window.yourcx) {
      return
    }
    window.yourcx('dimension', 'page.type', 'payment.gate.thank_you_page_positive')
    window.yourcx('survey', this.$VUE_YOURCX_CONFIG.product.insert.surveyId[this.currentLanguage], { product: 'insert' })
  }
}
</script>
