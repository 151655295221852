<template>
  <div class="order-details side" v-if="transaction">
    <h2 class="order-details-title uppercase" :aria-label="$t('orderDetails.headerAriaLabel')">{{ $t('orderDetails.header') }}</h2>

    <div class="amount-wrapper">
      <p>{{ $t('orderDetails.amountLabel') }}</p>
      <p>{{ getTransactionPayAmount() | amount }} {{ getTransactionPayCurrency() }}</p>
    </div>

    <div class="details-wrapper" v-if="showDetails">
      <order-details-cart v-if="isProductCartSet" />
      <div class="details-data" v-if="dccDetails">
        <p>{{ $t('orderDetails.amountOryginalCurrencyLabel', {currency: getTransactionOryginalCurrency()}) }}</p>
        <p>{{ getTransactionOryginalAmount() | amount }} {{ getTransactionOryginalCurrency() }}</p>
      </div>
      <div class="details-data" v-if="dccDetails">
        <p>{{ $t('orderDetails.currencyRateLabel') }}</p>
        <p>1 {{ getTransactionOryginalCurrency() }} = {{ getTransactionDccRate() }} {{ getTransactionPayCurrency() }}</p>
      </div>
      <div class="details-data" v-if="dccDetails || (visibility && visibility.showFrontFees)">
        <p>{{ $t('orderDetails.feeLabel') }}</p>
        <p>
          <span v-if="!dccDetails">
            {{ getTransactionCustomerFee() | amount }} {{ transaction.payAmountCurrency }}
          </span>
          <span v-if="dccDetails">
            {{ getTransactionCustomerFee() }}%
          </span>
        </p>
      </div>
      <div class="details-data">
        <p>{{ $t('orderDetails.orderLabel') }}</p>
        <p>{{ transaction.orderId }}</p>
      </div>
      <div class="details-data">
        <p>{{ $t('orderDetails.receiverLabel') }}</p>
        <p v-safe-html="transaction.receiverNameForFront"></p>
      </div>
      <div class="details-data" v-if="isServiceTypeMwf && transaction.customerNumber">
        <p>{{ $t('orderDetails.customerNumberLabel') }}</p>
        <p>{{ transaction.customerNumber }}</p>
      </div>
      <div class="details-data" v-if="isServiceTypeMwf && transaction.invoiceNumber">
        <p>{{ $t('orderDetails.invoiceNumberLabel') }}</p>
        <p>{{ transaction.invoiceNumber }}</p>
      </div>
      <div class="details-data" v-if="isServiceTypeMwf && transaction.accountNumber">
        <p>{{ $t('orderDetails.accountNumberLabel') }}</p>
        <p>{{ transaction.accountNumber }}</p>
      </div>
      <div class="details-data" v-if="payway">
        <p>{{ $t('orderDetails.paywayLabel') }}</p>
        <p>{{ $t('orderDetails.paywayName') }}</p>
      </div>
      <div class="details-data" v-if="payway">
        <p class="payway-img">
          <img :src="getPaywayImage(payway)" :alt="payway.nameOnList">
          <span>{{ payway.nameOnList }}</span>
        </p>
      </div>
    </div>

    <a class="show-more" href="" role="button" ref="moreDetailsButton" @keyup.32="toggleDetails" @click.prevent="showDetails = true" v-if="isProductCartSet && !showDetails">{{ $t('orderDetails.linkMore') }}</a>
    <a class="show-less" href="" role="button" ref="lessDetailsButton" @keyup.32="toggleDetails" @click.prevent="showDetails = false" v-if="isProductCartSet && showDetails">{{ $t('orderDetails.linkLess') }}</a>

    <div class="details-link" v-if="showButton && visibility && visibility.showReturnToShopButton">
      <button type="button"
              class="btn btn-plain link-back-to-shop"
              :title="$t('orderDetails.linkBackTitle')"
              v-if="allowReject"
              @click="sendPaymentReject">
        {{ $t('orderDetails.linkBack') }}
      </button>
      <button type="button"
              class="btn btn-plain link-back-to-shop"
              :title="$t('orderDetails.linkBack')"
              v-if="!allowReject"
              @click="goBackToShop(returnUrl)">
        {{ $t('orderDetails.linkBack') }}
      </button>
    </div>
  </div>
</template>

<script>
import orderDetailsMixin from '@/base/mixins/orderDetails.mixin'
import OrderDetailsCart from '@/base/components/OrderDetails/partial/cart'

export default {
  name: 'OrderDetailsSide',
  data () {
    return {
      showDetails: false
    }
  },
  components: { OrderDetailsCart },
  mixins: [orderDetailsMixin],
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    }
  },
  mounted () {
    this.showDetails = !this.isProductCartSet
  },
  updated () {
    if (this.showDetails) {
      this.$refs.lessDetailsButton && this.$refs.lessDetailsButton.focus()
    }
    if (!this.showDetails) {
      this.$refs.moreDetailsButton && this.$refs.moreDetailsButton.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/OrderDetails.scss";
</style>
