<template>
  <form ref="form-userlogin" @submit.prevent="submit" v-form:form novalidate>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('email')}">
      <label for="email">{{ $t('paywall.form.click2pay.loginForm.emailLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('email')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('email')">
        <span aria-hidden="true">{{ getFieldErrorText('email') }}</span>
      </p>
      <div class="input-text-wrapper" :class="{'is-error': isErrorForField('email')}">
        <input
          type="text"
          name="email"
          id="email"
          ref="email"
          :placeholder="$t('paywall.form.click2pay.loginForm.emailPlaceholder')"
          required
          email
          v-model.trim="formData.email" />
        <span class="input-flow-label">&nbsp;{{ $t('paywall.form.click2pay.loginForm.emailLabelFlow') }}&nbsp;</span>
        <span class="input-flow-mark"></span>
      </div>
    </div>
    <div class="submit-wrapper">
      <div class="c2p-submit">
        <button type="submit" class="btn" :class="{'inactive': isFormInvalid()}" :disabled="processing">
          <span>{{ $t('paywall.form.click2pay.loginForm.buttonSubmit') }}</span>
        </button>
      </div>
    </div>
    <div class="c2p-back">
      <button type="button" @click="goBack" class="btn btn-plain">
        {{ $t('paywall.form.click2pay.loginForm.buttonBack') }}
      </button>
    </div>
  </form>
</template>

<script>
import formMixin from '@/base/mixins/form.mixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CLICK2PAY_ERROR_MESSAGES, CLICK2PAY_VIEW } from '@/base/const/click2pay.const'

export default {
  name: 'ClickToPayLoginForm',
  mixins: [formMixin],
  data () {
    return {
      formData: {
        email: null
      }
    }
  },
  computed: {
    ...mapState({
      processing: state => state.click2pay.processing,
      idToken: state => state.click2pay.idToken,
      isProcessActive: state => state.click2pay.isProcessActive,
      selectedCard: state => state.click2pay.selectedCard
    }),
    ...mapGetters({
      profile: 'getClick2PayUserProfile'
    })
  },
  methods: {
    ...mapMutations([
      'SET_CLICK2PAY_PROCESSING',
      'SET_CLICK2PAY_EMAIL',
      'SET_CLICK2PAY_VIEW'
    ]),
    ...mapActions([
      'getClick2PayIdentityLookup'
    ]),
    goBack () {
      if (!this.isProcessActive) {
        this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.notRecognized)
        return
      }
      if (this.selectedCard) {
        this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.cardSelected)
        return
      }
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.newCard)
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    submit () {
      if (this.isFormInvalid()) {
        return false
      }
      this.SET_CLICK2PAY_PROCESSING(true)
      this.SET_CLICK2PAY_EMAIL(this.formData.email)
      this.getClick2PayIdentityLookup()
        .catch(error => {
          switch (error.toString()) {
            // konto zablokowane
            case CLICK2PAY_ERROR_MESSAGES.emailLocked:
              this.addErrorToField('email', 'emailLocked')
              break
            // email nierozpoznany / niezarejestrowany
            case CLICK2PAY_ERROR_MESSAGES.emailNotRecognized:
              this.addErrorToField('email', 'emailNotRecognized')
              break
            // pozostale błędy
            default:
              this.addErrorToField('email', 'emailIdentityLookup')
          }
          this.SET_CLICK2PAY_PROCESSING(false)
        })
        .finally(() => {
          this.SET_CLICK2PAY_PROCESSING(false)
        })
    }
  },
  mounted () {
    this.$refs['email'].focus()
    this.$emit('resetForm')
  },
  destroyed () {
    if (!this.isProcessActive) {
      this.$emit('resetCardWidget')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";
.c2p-action {
  button.btn.btn-plain {
    font-size: 12px;
    text-transform: unset;
  }
}
.submit-wrapper {
  padding-top: 10px;
  .c2p-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c2p-back {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  button {
    font-size: 12px;
  }
}
</style>
