<template>
  <div class="container">
    <div class="paywall">
      <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('paywall.frData.pageTitle') }}</h1>
      <div class="columns">
        <section class="sidebar-column">
          <order-details-side :transaction="transaction"
                              :visibility="visibilityOptions"
                              :show-button="true" />
        </section>
        <div class="main-column">
          <div class="content">
            <section class="payment-fr-data">
              <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.0754 52.1176C29.8009 52.1134 29.5312 52.1906 29.3006 52.3395C29.07 52.4885 28.8887 52.7025 28.7798 52.9545C28.6708 53.2065 28.6391 53.4852 28.6884 53.7552C28.7378 54.0253 28.8662 54.2747 29.0574 54.4718C29.2485 54.6689 29.4936 54.8049 29.762 54.8626C30.0304 54.9203 30.3099 54.8971 30.5651 54.796C30.8204 54.6949 31.0399 54.5203 31.196 54.2944C31.352 54.0685 31.4376 53.8014 31.4418 53.5269C31.445 53.3446 31.4121 53.1634 31.345 52.9938C31.278 52.8242 31.1781 52.6695 31.0512 52.5385C30.9242 52.4076 30.7726 52.303 30.6052 52.2308C30.4377 52.1586 30.2578 52.1201 30.0754 52.1176ZM30.0754 56.0465C29.7415 56.0502 29.4101 55.9879 29.1003 55.8632C28.7905 55.7384 28.5082 55.5536 28.2701 55.3194C28.032 55.0852 27.8426 54.8063 27.7127 54.4986C27.5828 54.1909 27.515 53.8606 27.5132 53.5266C27.5132 52.8471 27.7831 52.1954 28.2636 51.7148C28.7441 51.2343 29.3959 50.9644 30.0754 50.9644C30.755 50.9644 31.4067 51.2343 31.8872 51.7148C32.3677 52.1954 32.6376 52.8471 32.6376 53.5266C32.6359 53.8606 32.5681 54.191 32.4381 54.4987C32.3082 54.8064 32.1188 55.0853 31.8807 55.3195C31.6426 55.5537 31.3604 55.7385 31.0506 55.8633C30.7407 55.988 30.4094 56.0504 30.0754 56.0466V56.0465Z" fill="#7099B7"/>
                <path d="M36.396 8.04617H24.6523C24.5059 8.04357 24.3662 7.98424 24.2627 7.88068C24.1591 7.77711 24.0997 7.63741 24.0971 7.49097C24.0941 7.41521 24.1062 7.33959 24.1325 7.26848C24.1588 7.19738 24.1989 7.13216 24.2505 7.07661C24.3021 7.02105 24.3641 6.97622 24.4331 6.94472C24.5021 6.91322 24.5765 6.89568 24.6523 6.89307H36.396C36.4718 6.89568 36.5463 6.91322 36.6152 6.94472C36.6842 6.97622 36.7463 7.02105 36.7979 7.07661C36.8495 7.13216 36.8895 7.19738 36.9158 7.26848C36.9421 7.33959 36.9542 7.41521 36.9512 7.49097C36.9486 7.63741 36.8892 7.77711 36.7857 7.88068C36.6821 7.98424 36.5424 8.04357 36.396 8.04617Z" fill="#7099B7"/>
                <path d="M23.0286 2.15299C21.218 2.1583 19.4833 2.87984 18.203 4.16008C16.9228 5.44032 16.2011 7.17517 16.1958 8.9857V53.0141C16.2011 54.8246 16.9226 56.5595 18.2028 57.8397C19.4831 59.12 21.218 59.8416 23.0286 59.8469H38.6584C40.469 59.8416 42.2038 59.1201 43.4841 57.8398C44.7644 56.5595 45.486 54.8246 45.4914 53.0141V8.9857C45.486 7.17514 44.7644 5.44027 43.4841 4.16003C42.2038 2.87979 40.469 2.15827 38.6584 2.15299H23.0286ZM38.6584 60.9999H23.0286C18.5874 60.9999 15 57.4127 15 53.0136V8.9857C15 4.58717 18.5874 1 23.0286 1H38.6584C40.7752 1.00391 42.8042 1.84651 44.301 3.34326C45.7979 4.84 46.6406 6.8689 46.6447 8.9857V53.0141C46.6406 55.1308 45.798 57.1597 44.3012 58.6565C42.8045 60.1532 40.7756 60.9959 38.6588 60.9999H38.6584Z" fill="#7099B7"/>
                <path d="M35.755 42.2525H19.1431V41.0995H34.6023V25.1706H19.2712V24.0176H35.7554L35.755 42.2525Z" fill="#7099B7"/>
                <path d="M39.0432 37.9392H37.8901V21.7541H19.356V20.6011H39.0428L39.0432 37.9392Z" fill="#7099B7"/>
                <path d="M19.1431 37.3416V36.1456C19.5687 36.149 19.9906 36.0681 20.3848 35.9076C20.779 35.7471 21.1375 35.5102 21.4397 35.2104C21.7419 34.9107 21.9817 34.5541 22.1454 34.1612C22.309 33.7684 22.3933 33.347 22.3933 32.9214C22.3933 32.4958 22.309 32.0744 22.1454 31.6815C21.9817 31.2886 21.7419 30.932 21.4397 30.6323C21.1375 30.3325 20.779 30.0956 20.3848 29.9351C19.9906 29.7746 19.5687 29.6937 19.1431 29.6971V28.5445C19.7225 28.5417 20.2967 28.6533 20.8329 28.8731C21.369 29.0929 21.8565 29.4165 22.2672 29.8252C22.6779 30.2339 23.0039 30.7198 23.2263 31.2548C23.4487 31.7899 23.5631 32.3636 23.5631 32.9431C23.5631 33.5225 23.4487 34.0962 23.2263 34.6313C23.0039 35.1663 22.6779 35.6522 22.2672 36.0609C21.8565 36.4696 21.369 36.7932 20.8329 37.013C20.2967 37.2328 19.7225 37.3444 19.1431 37.3416Z" fill="#7099B7"/>
                <path d="M31.5273 31.9608C31.4515 31.9581 31.3771 31.9405 31.3081 31.909C31.2391 31.8775 31.1771 31.8328 31.1255 31.7772C31.0739 31.7217 31.0338 31.6564 31.0075 31.5853C30.9812 31.5142 30.9691 31.4386 30.9721 31.3629V27.7757C30.9691 27.7 30.9812 27.6243 31.0075 27.5532C31.0338 27.4821 31.0739 27.4169 31.1255 27.3614C31.1771 27.3059 31.2391 27.2611 31.3081 27.2296C31.3771 27.1981 31.4516 27.1805 31.5273 27.1779C31.6064 27.176 31.6849 27.1901 31.7583 27.2195C31.8318 27.2488 31.8985 27.2927 31.9544 27.3486C32.0103 27.4045 32.0544 27.4712 32.0837 27.5446C32.1131 27.618 32.1273 27.6967 32.1254 27.7757V31.3629C32.1273 31.4419 32.1131 31.5206 32.0837 31.594C32.0544 31.6674 32.0103 31.7341 31.9544 31.79C31.8985 31.8459 31.8318 31.8899 31.7583 31.9192C31.6849 31.9486 31.6064 31.9627 31.5273 31.9608Z" fill="#7099B7"/>
              </svg>
              <h2 class="normal" v-html="$t('paywall.frData.header')"></h2>
              <p class="copy-data-text" v-html="$t('paywall.frData.info')"></p>
              <div class="rows-wrapper">
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.timeLabel') }}</span>
                      <span>{{ paymentData.transactionValidityTime | date }}</span>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.nrbLabel') }}</span>
                      <span>{{ paymentFrData.nrb.value }}</span>
                    </p>
                  </div>
                  <div class="col">
                    <button class="btn btn-plain btn-copy" @click="copy(paymentFrData.nrb.value, $event)">{{ $t('paywall.frData.linkCopy') }}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.nameLabel') }}</span>
                      <span>{{ paymentFrData.name.value }}</span>
                    </p>
                  </div>
                  <div class="col">
                    <button class="btn btn-plain btn-copy" @click="copy(paymentFrData.name.value, $event)">{{ $t('paywall.frData.linkCopy') }}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.addressLabel') }}</span>
                      <span>{{ paymentFrData.address.value }}</span>
                    </p>
                  </div>
                  <div class="col">
                    <button class="btn btn-plain btn-copy" @click="copy(paymentFrData.address.value, $event)">{{ $t('paywall.frData.linkCopy') }}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.titleLabel') }}</span>
                      <span>{{ paymentFrData.title.value }}</span>
                    </p>
                  </div>
                  <div class="col">
                    <button class="btn btn-plain btn-copy" @click="copy(paymentFrData.title.value, $event)">{{ $t('paywall.frData.linkCopy') }}</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p>
                      <span>{{ $t('paywall.frData.amountLabel') }}</span>
                      <span>{{ paymentFrData.amount.value | amount }} {{ paymentFrData.currency.value }}</span>
                    </p>
                  </div>
                  <div class="col">
                    <button class="btn btn-plain btn-copy" @click="copy(paymentFrData.amount.value, $event)">{{ $t('paywall.frData.linkCopy') }}</button>
                  </div>
                </div>
              </div>
              <div class="summary">
                <a :href="getPdfUrl(paymentData.additionalData.pdfHash)"
                   :title="$t('paywall.frData.linkGetPdfTitle')"
                   target="_blank"
                   class="btn btn-plain btn-download">
                  {{ $t('paywall.frData.linkGetPdf') }}
                </a>
                <a :href="paymentFrData.url.value"
                   v-if="canShowLoginButton"
                   target="_blank"
                   rel="noreferrer"
                   class="btn button-go-to-bank">
                  {{ $t('paywall.frData.linkGoToBank') }}
                </a>
              </div>
              <div class="row">
                <div class="change-payment-wrapper" v-if="showReturnToPaywaysButton">
                  <p>{{ $t('paywall.form.changePaywayLink1') }} <a href="" role="button" @click.prevent="setPaywallModeDefault">{{ $t('paywall.form.changePaywayLink2') }}</a></p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import { MESSAGES } from '@/base/const/texts.const'
import { clipboardClass } from '@/base/class/clipboard.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import { MOBILE_STATUS } from '@/base/const/mobileEvents.const'
import { pageTitle } from '@/base/const/routing.const'
import { ENDPOINTS } from '@/base/const/endpoints.const'

export default {
  name: 'PaymentFrData',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().start + ' - ' + pageTitle().frData
    }
  },
  data () {
    return {
      pdfInProgress: false
    }
  },
  components: {
    OrderDetailsSide
  },
  computed: {
    ...mapState({
      apiToken: state => state.payment.apiToken,
      paywayId: state => state.payment.form.paywayID,
      paymentFrData: state => state.payment.paymentData.frData,
      paymentData: state => state.payment.paymentData,
      transaction: state => state.payment.paymentData.transaction,
      visibilityOptions: state => state.payment.paymentData.visibilityOptions,
      showReturnToPaywaysButton: state => state.payment.paymentData.visibilityOptions
        ? state.payment.paymentData.visibilityOptions.showReturnToPaywaysButton
        : null
    }),
    canShowLoginButton: function () {
      return !!this.paymentFrData.url.value &&
        this.paymentFrData.url.value.length > 0 &&
        this.paymentFrData.showBankUrlOnFront
    }
  },
  methods: {
    ...mapActions([
      'addMessageSuccess',
      'addMessageError'
    ]),
    copy (value, event) {
      setTimeout(() => {
        event.target.focus()
      })

      if (!value) {
        return
      }

      clipboardClass.copyToClipboard(value).then(() => {
        const message = {
          text: MESSAGES().success.copy,
          options: {
            time: 2000
          }
        }
        this.addMessageSuccess(message)
      }).catch(() => {
        const message = {
          text: MESSAGES().errors.copy
        }
        this.addMessageError(message)
      })
    },
    getPdfUrl (hash) {
      return `${ENDPOINTS.getPdf}?hash=${hash}`
    },
    setPaywallModeDefault () {
      location.reload()
    }
  },
  mounted () {
    MobileAppClass.postMessageSuccess(MOBILE_STATUS().paywayLoaded)
    MobileAppClass.supportExternalLinks()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";
</style>
