<template>
  <div>
    <order-details-top :transaction="transaction"
                       :dccDetails="dccDetails"
                       :visibility="visibilityOptions"
                       :show-button="false"
                       v-if="canShowMobileOrderDetails"/>
    <main>
      <confirmation-loading v-if="isLoading" />
      <div v-if="!isLoading">
        <error-data v-if="isErrorDataSet" />
        <div v-else>
          <div class="container">
            <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('confirmation.pageTitle') }}</h1>
            <div class="columns confirmation">
              <section class="sidebar-column" v-if="!isNotFound()">
                <order-details-side :transaction="transaction"
                                    :dccDetails="dccDetails"
                                    :visibility="visibilityOptions"
                                    :show-button="false" />
              </section>
              <section class="main-column" :class="{'column-full': isNotFound()}">
                <div class="content confirmation-wrapper">
                  <confirmation-positive v-if="isPositive()" />
                  <confirmation-negative v-if="isNegative()" />
                  <confirmation-incorrect-amount v-if="isIncorrectAmount()" />
                  <confirmation-pending v-if="isPending()" />
                  <confirmation-multiple-paid v-if="isMultiplePaid()" />
                  <confirmation-outdated v-if="isOutdated()" />
                  <confirmation-outdated-paid-not-refunded v-if="isOutdatedPaidNotRefunded()" />
                  <confirmation-outdated-paid-refunded v-if="isOutdatedPaidRefunded()" />
                  <confirmation-not-found v-if="isNotFound()" />
                  <your-cx-survey v-if="canShowYourCxSurvey" />
                  <partner-return-btn v-if="visibilityOptions.showReturnToShopButton" />
                </div>
              </section>
            </div>
            <ads-banner v-if="isDefaultLangActive" :service-id="$route.query.ServiceID" :payway-id="paywayId" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ErrorData from '@/base/components/Error/Data'
import ConfirmationLoading from '@/base/components/Confirmation/Loading'
import OrderDetailsSide from '@/base/components/OrderDetails/Side'
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import ConfirmationPositive from '@/base/components/Confirmation/Templates/Positive'
import ConfirmationNegative from '@/base/components/Confirmation/Templates/Negative'
import ConfirmationIncorrectAmount from '@/base/components/Confirmation/Templates/IncorrectAmount'
import ConfirmationPending from '@/base/components/Confirmation/Templates/Pending'
import ConfirmationMultiplePaid from '@/base/components/Confirmation/Templates/MultiplePaid'
import ConfirmationOutdated from '@/base/components/Confirmation/Templates/Outdated'
import ConfirmationOutdatedPaidNotRefunded from '@/base/components/Confirmation/Templates/OutdatedPaidNotRefunded'
import ConfirmationOutdatedPaidRefunded from '@/base/components/Confirmation/Templates/OutdatedPaidRefunded'
import ConfirmationNotFound from '@/base/components/Confirmation/Templates/NotFound'
import PartnerReturnBtn from '@/base/components/Confirmation/PartnerReturnBtn'
import { CONFIRMATION_STATE } from '@/base/const/confirmation.const'
import { ToolsClass } from '@/base/class/tools.class'
import viewMixin from '@/base/mixins/view.mixin'
import { pageTitle } from '@/base/const/routing.const'
import AdsBanner from '@/base/components/Ads/Banner'
import { PAYMENT_SERVICE_TYPE } from '@/base/const/payment.const'
import YourCxSurvey from '@/base/components/YourCx/survey.vue'
import { COOKIE_DCC } from '@/base/const/cookies.const'
import { CookieClass } from '@/base/class/cookie.class'

export default {
  name: 'Confirmation',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().confirmation
    }
  },
  mixins: [viewMixin],
  components: {
    YourCxSurvey,
    AdsBanner,
    PartnerReturnBtn,
    ConfirmationNotFound,
    ConfirmationOutdatedPaidRefunded,
    ConfirmationOutdatedPaidNotRefunded,
    ConfirmationOutdated,
    ConfirmationMultiplePaid,
    ConfirmationPending,
    ConfirmationIncorrectAmount,
    ConfirmationNegative,
    ConfirmationPositive,
    OrderDetailsSide,
    OrderDetailsTop,
    ConfirmationLoading,
    ErrorData
  },
  computed: {
    ...mapState({
      isLoading: state => state.confirmation.loading,
      state: state => state.confirmation.data ? state.confirmation.data.state : null,
      isConfirmationDataSet: state => state.confirmation.data && state.confirmation.data.transaction,
      transaction: state => state.confirmation.data?.transaction,
      visibilityOptions: state => state.confirmation.data.visibilityOptions,
      isServiceTypeEcommerce: state => state.confirmation.data.serviceType === PAYMENT_SERVICE_TYPE.ecommerce,
      isServiceTypeMwf: state => state.confirmation.data.serviceType === PAYMENT_SERVICE_TYPE.mwf,
      paywayId: state => state.confirmation.data.transaction.paywayId
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet',
      isDefaultLangActive: 'isDefaultLangActive',
      isLangForYourCxActive: 'isLangForYourCxActive',
      currentLanguage: 'getCurrentLanguage',
      isAdsBannerDataSet: 'isAdsBannerDataSet'
    }),
    dccDetails: function () {
      let cookieDccList = CookieClass.getCookie(COOKIE_DCC)
      if (!cookieDccList || !cookieDccList.length) {
        return false
      }
      cookieDccList = JSON.parse(cookieDccList)
      if (!Array.isArray(cookieDccList)) {
        return false
      }
      return cookieDccList.find(dcc => dcc.idTransRemote === this.transaction?.transRemoteID)
    },
    canShowMobileOrderDetails: function () {
      return !this.isLoading && this.isConfirmationDataSet
    },
    canShowYourCxSurvey: function () {
      return !this.isLoading &&
        this.$VUE_YOURCX_CONFIG.product.insert.active &&
        !!this.$VUE_YOURCX_CONFIG.product.insert.surveyId[this.currentLanguage] &&
        this.isLangForYourCxActive &&
        !this.isAdsBannerDataSet &&
        (this.isPositive() || this.isNegative())
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_CONFIRMATION_LOADING'
    ]),
    ...mapActions([
      'getConfirmationTrx'
    ]),
    isPositive () {
      return this.state === CONFIRMATION_STATE.positive
    },
    isNegative () {
      return this.state === CONFIRMATION_STATE.negative
    },
    isIncorrectAmount () {
      return this.state === CONFIRMATION_STATE.incorrectAmount
    },
    isPending () {
      return this.state === CONFIRMATION_STATE.pending
    },
    isMultiplePaid () {
      return this.state === CONFIRMATION_STATE.multiplePaid
    },
    isOutdated () {
      return this.state === CONFIRMATION_STATE.outdated
    },
    isOutdatedPaidNotRefunded () {
      return this.state === CONFIRMATION_STATE.outdatedPaidNotRefunded
    },
    isOutdatedPaidRefunded () {
      return this.state === CONFIRMATION_STATE.outdatedPaidRefunded
    },
    isNotFound () {
      return this.state === CONFIRMATION_STATE.transactionNotFound
    }
  },
  created () {
    this.SET_CONFIRMATION_LOADING(true)
  },
  mounted () {
    ToolsClass.scrollToElementTop(document.querySelector('header'))
    const data = {
      orderId: this.$route.query.OrderID,
      serviceId: this.$route.query.ServiceID,
      hash: this.$route.query.Hash
    }
    this.getConfirmationTrx(data)
      .catch(error => {
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorMessage: error.body.errorMessage,
          errorUrl: error.body.errorUrl
        })
      })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setWindowSize)
  }
}
</script>
