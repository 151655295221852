<template>
  <div>
    <order-details-top :transaction="transaction"
                       :visibility="visibilityOptions"
                       :show-button="true"
                       v-if="canShowMobileOrderDetails"/>
    <main>
      <payment-loading v-if="isLoading"/>
      <div v-else>
        <error-data v-if="isErrorDataSet"/>
        <div v-else>
          <div v-if="isProcessing">
            <visa-mobile-data v-if="isVisaMobilePaywaySet" />
            <payment-processing v-else />
          </div>
          <div v-else>
            <payment-paywall v-if="isPaymentPaywaysDataSet"/>
            <payment-fr-data v-if="isPaymentFrDataSet"/>
            <visa-mobile-data v-if="isPaymentVisaMobileDataSet" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import PaymentFrData from '@/base/components/Payment/Data/FrData'
import PaymentPaywall from '@/base/components/Payment/Paywall/Paywall'
import PaymentLoading from '@/base/components/Payment/Loader/Loading'
import PaymentProcessing from '@/base/components/Payment/Processing/Processing'
import VisaMobileData from '@/base/components/Payment/VisaMobileProcess/Data'
import { PAYMENT_CLIENT_SOURCE } from '@/base/const/payment.const'
import ErrorData from '@/base/components/Error/Data'
import { DateClass } from '@/base/class/date.class'
import viewMixin from '@/base/mixins/view.mixin'
import { VISA_MOBILE_TYPES } from '@/base/const/payway.const'
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'Continue',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().continue
    }
  },
  mixins: [viewMixin],
  components: {
    VisaMobileData,
    OrderDetailsTop,
    ErrorData,
    PaymentProcessing,
    PaymentLoading,
    PaymentPaywall,
    PaymentFrData
  },
  props: {
    idTransRemote: {
      type: String,
      required: true
    },
    continueHash: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading,
      isProcessing: state => state.payment.processing,
      isPaymentFrDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.frData,
      isPaymentPaywaysDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.payways,
      transaction: state => state.payment.paymentData?.transaction?.orderId ? state.payment.paymentData.transaction : state.confirmation.data?.transaction,
      visibilityOptions: state => state.payment.paymentData ? state.payment.paymentData.visibilityOptions : null,
      apiToken: state => state.payment.apiToken,
      isPaymentVisaMobileDataSet: state => state.payment.paymentData && state.payment.paymentData.clientSource === PAYMENT_CLIENT_SOURCE.visaMobileData,
      isVisaMobilePaywaySet: state => state.payment.form.paywayID === VISA_MOBILE_TYPES.visaMobile.id
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    }),
    canShowMobileOrderDetails: function () {
      return !this.$VUE_HIDE_TRANSACTION_INFO && !this.isLoading && (this.isPaymentPaywaysDataSet || this.isPaymentFrDataSet || this.isPaymentVisaMobileDataSet)
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_TIMER_TRX_GET_START',
      'SET_TIMER_TRX_START_LOADED'
    ]),
    ...mapActions([
      'getPaymentContinueTrx'
    ])
  },
  mounted () {
    const data = {
      idTransRemote: this.idTransRemote,
      continueHash: this.continueHash
    }
    this.SET_TIMER_TRX_GET_START(DateClass.getCurrentTimestamp())
    this.getPaymentContinueTrx(data)
      .finally(() => {
        this.SET_TIMER_TRX_START_LOADED(DateClass.getCurrentTimestamp())
      })
      .catch(error => {
        // przekierowanie na potykacz PISowy jeśli nie ma danych psd2 dla startu WL z kanałem PISowym
        if (error.message === 'psd2Data') {
          this.$router.push({ name: 'RegulationsApprovalContinue', params: { idTransRemote: this.idTransRemote, continueHash: this.continueHash } })
          return
        }
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
  }
}
</script>
