<template>
  <div class="timer-wrapper" v-if="isTimerSet && showTimer && isSessionTimerActive">
    <div class="timer-wrapper-inactive" v-if="!isAnyTimeLeft">
      <div class="time-desc">
        <span class="time-text">{{ $t('header.sessionTimer.timeOut') }}</span>
      </div>
    </div>
    <div class="timer-wrapper-active" v-if="isAnyTimeLeft">
      <div class="time-desc">
        <span class="time-text">{{ $t('header.sessionTimer.sessionEnd') }}</span>
        <span class="time-counter">
          <span>{{ minutesLeft }}:{{ secondsLeft }}</span>
          <button class="btn btn-plain normal-text"
                  @click="refresh()"
                  :aria-label="$t('header.sessionTimer.label')"
                  :disabled="isSessionRefreshing">
            {{ $t('header.sessionTimer.button') }}
          </button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import { ROUTING } from '@/base/const/routing.const'

const SHOW_TIMER = Math.round(5 * 60 * 1000)

export default {
  name: 'SessionTimer',
  data () {
    return {
      endTime: null,
      hoursLeft: null,
      minutesLeft: null,
      secondsLeft: null,
      timeoutHandler: null,
      isTimerSet: false,
      isAnyTimeLeft: null,
      showTimer: false
    }
  },
  watch: {
    isSessionTimerActive: function (newValue) {
      if (newValue === false) {
        clearTimeout(this.timeoutHandler)
        this.isAnyTimeLeft = false
      }
    }
  },
  computed: {
    ...mapState({
      isSessionTimerActive: state => state.payment.session.active,
      isSessionRefreshing: state => state.payment.session.refreshing,
      paymentData: state => state.payment.paymentData
    })
  },
  methods: {
    ...mapActions([
      'getSessionRemainingTime',
      'refreshSession',
      'rejectSession'
    ]),
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_PAYMENT_PAYWALL_VIEW'
    ]),
    refresh () {
      this.refreshSession().then(sessionRemainingTime => {
        this.countdown(sessionRemainingTime)
      })
    },
    countdown (seconds) {
      clearTimeout(this.timeoutHandler)
      this.endTime = +new Date() + seconds * 1000
      this.updateTimer()
    },
    updateTimer () {
      const millisecondsLeft = this.endTime - (+new Date())
      if (millisecondsLeft <= SHOW_TIMER) {
        if (!this.showTimer) {
          this.showTimer = true
        }
      } else {
        if (this.showTimer) {
          this.showTimer = false
        }
      }
      if (millisecondsLeft < 1000) {
        this.isAnyTimeLeft = false
        this.rejectSession()
          .then(() => {
            this.SET_PAYMENT_PAYWALL_VIEW(false)
            this.$router.push(ROUTING.sessionTimeout)
          })
          .catch(() => {})
        return
      }
      const time = new Date(millisecondsLeft)
      const hours = time.getUTCHours()
      const minutes = time.getUTCMinutes()
      const seconds = time.getUTCSeconds()

      this.hoursLeft = twoDigits(hours)
      this.minutesLeft = twoDigits(minutes)
      this.secondsLeft = twoDigits(seconds)
      this.timeoutHandler = setTimeout(this.updateTimer, 1000)

      function twoDigits (number) {
        return (number <= 9 ? '0' + number : number + '')
      }
    },
    setSessionRemainingTime (sessionRemainingTime) {
      this.isTimerSet = true
      this.isAnyTimeLeft = sessionRemainingTime > 0
      if (this.isAnyTimeLeft) {
        this.countdown(sessionRemainingTime)
      }
    }
  },
  created () {
    if (this.paymentData.sessionRemainingTime) {
      this.setSessionRemainingTime(this.paymentData.sessionRemainingTime)
    } else {
      this.getSessionRemainingTime().then(sessionRemainingTime => {
        this.setSessionRemainingTime(sessionRemainingTime)
      })
    }
  }
}
</script>
