<template>
  <div class="loader"><span class="sr-only" v-html="$t('loader.defaultAnimation')"></span>
    <svg id="small-blade" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.5 341.52">
      <g>
        <circle class="cls-1" cx="196.29" cy="225.54" r="15.13"/>
        <path class="cls-1" d="M273.19,447.28,291,262.89s-1.09-12.4,6.5-12.4S304,262.3,304,262.3l17.44,184.75" transform="translate(-101.25 -249.74)"/>
        <path class="cls-1" d="M284.91,510.34,116.8,586.86s-10.2,7.15-14,.58,7-11.53,7-11.53L262.18,467.13" transform="translate(-101.25 -249.74)"/>
        <path class="cls-1" d="M333.57,467.91,484.72,575.83s11.29,5.26,7.5,11.83-13.48-.28-13.48-.28L310.63,510.84" transform="translate(-101.25 -249.74)"/>
      </g>
    </svg>
    <svg id="big-blade" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.5 341.52">
      <g>
        <circle class="cls-1" cx="196.29" cy="225.54" r="15.13"/>
        <path class="cls-1" d="M273.19,447.28,291,262.89s-1.09-12.4,6.5-12.4S304,262.3,304,262.3l17.44,184.75" transform="translate(-101.25 -249.74)"/>
        <path class="cls-1" d="M284.91,510.34,116.8,586.86s-10.2,7.15-14,.58,7-11.53,7-11.53L262.18,467.13" transform="translate(-101.25 -249.74)"/>
        <path class="cls-1" d="M333.57,467.91,484.72,575.83s11.29,5.26,7.5,11.83-13.48-.28-13.48-.28L310.63,510.84" transform="translate(-101.25 -249.74)"/>
      </g>
    </svg>
    <svg width="245" height="176" viewBox="0 0 245 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="small-cloud">
        <path d="M112.222 85.9652C111.448 85.9582 110.679 86.1049 109.962 86.3965C109.244 86.6882 108.591 87.1192 108.041 87.6646C107.49 88.21 107.054 88.8589 106.756 89.574C106.457 90.2891 106.304 91.056 106.304 91.8307C106.304 92.6054 106.457 93.3727 106.756 94.0878C107.054 94.8029 107.49 95.4518 108.041 95.9971C108.591 96.5425 109.244 96.9733 109.962 97.2649C110.679 97.5566 111.448 97.7032 112.222 97.6962C113.315 97.6962 115.73 97.7541 118.203 97.8115C121.136 97.869 124.185 97.9268 125.852 97.9268C131.488 97.9268 136.088 93.3839 136.088 87.7476C136.089 85.1929 135.134 82.7302 133.412 80.8436C131.689 78.9571 129.323 77.783 126.779 77.5524C124.234 77.3218 121.696 78.0513 119.662 79.5974C117.628 81.1435 116.246 83.3944 115.788 85.9077C115.767 86.0272 115.72 86.1404 115.65 86.2397C115.581 86.3391 115.49 86.4222 115.385 86.4829C115.279 86.5395 115.161 86.569 115.04 86.569C114.92 86.569 114.802 86.5395 114.696 86.4829C113.916 86.1413 113.074 85.9649 112.222 85.9652ZM125.851 99.537C124.183 99.537 121.136 99.4795 118.203 99.4224C115.673 99.3646 113.315 99.3071 112.222 99.3071C110.254 99.285 108.374 98.4875 106.99 97.0879C105.606 95.6883 104.83 93.7993 104.83 91.831C104.83 89.8627 105.606 87.9741 106.99 86.5745C108.374 85.1749 110.254 84.3774 112.222 84.3553C112.983 84.3572 113.739 84.4735 114.465 84.7002C115.034 82.5744 116.186 80.6501 117.792 79.1455C119.398 77.6408 121.393 76.6156 123.551 76.186C125.709 75.7563 127.945 75.9395 130.004 76.7144C132.064 77.4893 133.865 78.8252 135.205 80.571C136.545 82.3167 137.369 84.4026 137.585 86.5926C137.801 88.7826 137.399 90.9893 136.426 92.9629C135.452 94.9365 133.946 96.5983 132.077 97.7602C130.208 98.9221 128.052 99.5377 125.851 99.5374L125.851 99.537Z" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M76.3232 65.329L77.985 69.9581L79.3291 127.83C79.3385 127.934 79.326 128.038 79.2925 128.136C79.2591 128.234 79.2054 128.324 79.1348 128.4C79.0643 128.476 78.9784 128.536 78.8831 128.576C78.7877 128.617 78.6842 128.637 78.5806 128.636C78.4785 128.64 78.3769 128.623 78.2811 128.588C78.1854 128.552 78.0974 128.498 78.0226 128.429C77.9478 128.359 77.8873 128.276 77.8449 128.183C77.8025 128.09 77.7789 127.99 77.7754 127.888L76.3232 65.329Z" />
      <path d="M28.7004 129.852C28.2402 129.795 27.8954 129.45 27.9525 128.99L30.3905 86.2476C30.4476 85.7874 30.7926 85.4425 31.2528 85.5C31.6553 85.5 32.0001 85.9025 32.0001 86.3051L29.5059 129.105C29.5023 129.207 29.4785 129.307 29.4361 129.4C29.3937 129.493 29.3332 129.577 29.2584 129.646C29.1836 129.716 29.0959 129.77 29.0002 129.805C28.9044 129.84 28.8025 129.856 28.7004 129.852Z" />
      <path d="M37.4877 129C37.3857 129.004 37.284 128.988 37.1883 128.952C37.0925 128.917 37.0045 128.863 36.9297 128.794C36.8549 128.724 36.7944 128.641 36.752 128.548C36.7096 128.455 36.686 128.354 36.6826 128.252L34.7524 83.8051C34.7524 83.4023 35.0972 83 35.5 83C35.6021 82.9957 35.625 82.9647 35.7208 83C35.8167 83.0353 35.9837 83.1364 36.0586 83.206C36.1334 83.2755 36.0803 82.8845 36.1226 82.9775C36.1649 84.215 36.3019 83.6455 36.3051 83.7476L38.235 128.195C38.2445 128.298 38.2322 128.402 38.1987 128.5C38.1653 128.598 38.1115 128.688 38.041 128.764C37.9705 128.84 37.8849 128.901 37.7896 128.941C37.6942 128.982 37.5913 129.002 37.4877 129Z" />
      <path d="M129.297 64H102.148C101.94 63.9957 101.742 63.9117 101.595 63.7653C101.448 63.619 101.363 63.4217 101.359 63.2147C101.356 63.1108 101.375 63.0075 101.414 62.911C101.453 62.8146 101.511 62.7271 101.584 62.6536C101.658 62.5801 101.746 62.5221 101.843 62.4834C101.94 62.4448 102.043 62.4261 102.148 62.4285H129.297C131.717 62.4285 134.039 61.4704 135.75 59.7651C137.461 58.0597 138.423 55.7469 138.423 53.3351C138.423 50.9233 137.461 48.6102 135.75 46.9048C134.039 45.1994 131.717 44.2414 129.297 44.2414C128.257 44.2317 127.224 44.4224 126.256 44.8028C126.143 44.8394 126.022 44.8481 125.904 44.8286C125.787 44.8091 125.675 44.7618 125.58 44.6906C125.49 44.6501 125.412 44.5879 125.353 44.5093C125.294 44.4308 125.256 44.3385 125.242 44.2414C124.148 42.4856 122.621 41.0377 120.807 40.0355C118.993 39.0334 116.952 38.5103 114.878 38.5157C108.625 38.5157 103.386 43.1749 102.71 49.2934C102.697 49.4125 102.655 49.5263 102.586 49.6244C102.517 49.7225 102.424 49.8014 102.316 49.8542C102.147 50.0232 101.866 50.0233 101.64 49.9668C101.527 49.9107 97.9223 48.6762 95.3874 51.6505C95.2384 51.7944 95.0422 51.8796 94.8349 51.8899C94.6276 51.9003 94.4234 51.8352 94.2606 51.7069C93.9232 51.4264 93.9231 50.9771 94.2043 50.6401C96.4016 48.0579 99.443 47.89 101.302 48.2263C101.809 45.5378 103.108 43.0608 105.033 41.111C106.959 39.1612 109.423 37.8271 112.113 37.2791C114.802 36.731 117.594 36.994 120.133 38.0341C122.672 39.0741 124.843 40.844 126.368 43.1185C127.32 42.8466 128.307 42.7143 129.297 42.7257C135.212 42.7257 140 47.4969 140 53.3348C140.001 54.7356 139.724 56.1227 139.187 57.417C138.649 58.7112 137.861 59.8873 136.867 60.8777C135.873 61.8682 134.693 62.6538 133.394 63.1895C132.095 63.7252 130.703 64.0007 129.297 64Z" />
      <path d="M68.401 128.635H68.3435C67.9411 128.635 67.5962 128.233 67.5962 127.831L70.6412 56.5118C70.6412 56.1094 71.044 55.7645 71.4463 55.7645C71.55 55.7627 71.6528 55.7826 71.7482 55.8232C71.8436 55.8638 71.9295 55.924 72 56C72.0705 56.0759 72.1243 56.1659 72.1577 56.2641C72.1911 56.3622 72.2035 56.4664 72.194 56.5696L69.2064 127.888C69.1807 128.087 69.0861 128.271 68.939 128.407C68.7919 128.544 68.6015 128.624 68.401 128.635Z" />
      <path d="M194.589 97.5485C194.591 97.3108 194.545 97.0751 194.455 96.8551C194.365 96.6352 194.232 96.4353 194.063 96.2672C193.895 96.0991 193.696 95.9661 193.476 95.8758C193.256 95.7855 193.02 95.7399 192.782 95.7414C192.544 95.7377 192.308 95.7823 192.087 95.8727C191.867 95.9631 191.667 96.0972 191.501 96.2671C191.166 96.6081 190.98 97.0673 190.982 97.5449C190.984 98.0225 191.175 98.48 191.513 98.8177C191.851 99.1554 192.308 99.3461 192.786 99.3484C193.263 99.3507 193.723 99.1644 194.063 98.8299L194.096 98.797C194.411 98.4571 194.587 98.0117 194.589 97.5485ZM195.148 95.1828C195.773 95.8027 196.127 96.6449 196.134 97.5253C196.14 98.4057 195.797 99.2527 195.181 99.8813L195.148 99.914C194.679 100.381 194.083 100.699 193.434 100.828C192.785 100.956 192.112 100.89 191.501 100.637C190.889 100.384 190.367 99.9556 189.998 99.406C189.63 98.8564 189.432 98.21 189.431 97.5483C189.424 96.665 189.767 95.8149 190.384 95.1827H190.416C190.726 94.8703 191.095 94.6224 191.501 94.4532C191.907 94.2841 192.342 94.1969 192.782 94.1969C193.222 94.1969 193.657 94.2841 194.063 94.4532C194.47 94.6224 194.838 94.8703 195.148 95.1827V95.1828Z" />
      <path d="M185.685 37.881C185.477 37.8782 185.278 37.7942 185.131 37.6469C184.983 37.4996 184.899 37.3007 184.896 37.0925C184.899 36.991 184.921 36.891 184.961 36.7981C185.002 36.7052 185.061 36.6213 185.134 36.5511C185.207 36.4809 185.294 36.4259 185.388 36.3891C185.483 36.3524 185.584 36.3346 185.685 36.3369H201.062C201.161 36.3353 201.26 36.3537 201.353 36.3911C201.445 36.4285 201.529 36.4841 201.6 36.5546C201.67 36.6251 201.726 36.709 201.763 36.8014C201.8 36.8938 201.819 36.9928 201.817 37.0925C201.82 37.1939 201.802 37.2948 201.765 37.3893C201.728 37.4839 201.673 37.5702 201.603 37.6435C201.533 37.7167 201.449 37.7754 201.356 37.8162C201.263 37.857 201.163 37.879 201.062 37.881H185.685Z" />
      <path d="M204.052 107.406H183.517C180.731 107.397 178.061 106.287 176.091 104.317C174.121 102.347 173.011 99.6774 173.002 96.8914V39.0968C173 37.7113 173.271 36.339 173.801 35.0589C174.332 33.7788 175.11 32.6163 176.091 31.6383V31.6712C177.065 30.6929 178.221 29.9164 179.496 29.3864C180.77 28.8564 182.137 28.5832 183.517 28.5826H204.052C205.433 28.5833 206.799 28.8565 208.073 29.3865C209.347 29.9165 210.504 30.693 211.478 31.6712V31.6383C212.459 32.6163 213.237 33.7789 213.767 35.0589C214.297 36.339 214.569 37.7113 214.566 39.0968V96.8915C214.558 99.6776 213.448 102.347 211.478 104.317C209.507 106.287 206.838 107.398 204.052 107.406L204.052 107.406ZM183.516 105.861H204.052C206.431 105.86 208.712 104.915 210.394 103.233C212.075 101.551 213.021 99.27 213.022 96.8914V39.0968C213.023 37.9187 212.792 36.7519 212.34 35.6637C211.889 34.5754 211.228 33.587 210.393 32.7554C209.562 31.9199 208.574 31.2575 207.486 30.8063C206.397 30.3551 205.23 30.1242 204.052 30.1268H183.517C182.338 30.121 181.17 30.3505 180.081 30.8019C178.992 31.2532 178.004 31.9174 177.175 32.7554C176.341 33.5869 175.679 34.5753 175.228 35.6636C174.777 36.7519 174.545 37.9187 174.547 39.0968V96.8915C174.548 99.2701 175.493 101.551 177.175 103.233C178.857 104.915 181.138 105.86 183.516 105.861L183.516 105.861Z" />
      <path d="M204.5 58.8106H236.24V82.796H204.5V81.2516H234.729V60.3549L204.5 60.3549L204.5 58.8106Z" />
      <path d="M204.963 58.8106H190.646V82.796H205.127V81.2517H192.157V60.3549H204.963L204.963 58.8106Z" />
      <path d="M204 54.3093H240.577V77.0789H239.033V55.8535H204V54.3093Z" />
      <path d="M204.983 54.3034L196.198 54.3095H195.41V60.2373H196.954V55.8538L204.983 55.8476V54.3034Z" />
      <path d="M214.457 74.7462C215.574 74.7501 216.649 74.3134 217.447 73.5305C217.843 73.1363 218.157 72.6678 218.371 72.1519C218.585 71.636 218.696 71.0828 218.696 70.5242C218.696 69.9656 218.585 69.4125 218.371 68.8966C218.157 68.3807 217.843 67.9121 217.447 67.5179C216.649 66.7351 215.574 66.2984 214.457 66.3022V64.7578C215.213 64.7549 215.962 64.9014 216.661 65.189C217.36 65.4766 217.996 65.8995 218.531 66.4334C219.072 66.9684 219.502 67.6054 219.795 68.3076C220.088 69.0098 220.239 69.7632 220.239 70.5241C220.239 71.2851 220.088 72.0385 219.795 72.7407C219.502 73.4429 219.072 74.0799 218.531 74.6148C217.996 75.1488 217.36 75.5717 216.661 75.8593C215.962 76.1469 215.213 76.2934 214.457 76.2905V74.7462Z" />
      <path d="M214.783 66.302C213.665 66.2981 212.59 66.7349 211.792 67.5178C211.396 67.912 211.082 68.3805 210.868 68.8964C210.654 69.4123 210.543 69.9654 210.543 70.5241C210.543 71.0827 210.654 71.6358 210.868 72.1517C211.082 72.6676 211.396 73.1361 211.792 73.5304C212.59 74.3131 213.665 74.7499 214.783 74.7461L214.783 76.2905C214.027 76.2934 213.277 76.1469 212.578 75.8593C211.879 75.5717 211.244 75.1488 210.709 74.6148C210.167 74.0799 209.738 73.4429 209.444 72.7407C209.151 72.0385 209 71.2851 209 70.5241C209 69.7632 209.151 69.0098 209.444 68.3076C209.738 67.6054 210.167 66.9684 210.709 66.4335C211.244 65.8995 211.879 65.4766 212.578 65.189C213.277 64.9014 214.027 64.7549 214.783 64.7578L214.783 66.302Z" />
      <path d="M229.932 63.7392C229.931 63.6369 229.95 63.5353 229.988 63.4403C230.026 63.3453 230.083 63.2589 230.154 63.186C230.226 63.1131 230.312 63.0552 230.406 63.0157C230.501 62.9762 230.602 62.9558 230.704 62.9558C230.807 62.9558 230.908 62.9762 231.003 63.0157C231.097 63.0552 231.183 63.1131 231.254 63.186C231.326 63.2589 231.383 63.3453 231.421 63.4403C231.459 63.5353 231.478 63.6369 231.477 63.7392V68.5035C231.473 68.7056 231.39 68.898 231.245 69.0395C231.101 69.181 230.907 69.2602 230.704 69.2602C230.502 69.2602 230.308 69.181 230.164 69.0395C230.02 68.898 229.937 68.7056 229.933 68.5035L229.932 63.7392Z" />
      <path d="M196.954 73.002C196.956 72.8992 196.938 72.7971 196.9 72.7016C196.862 72.6061 196.805 72.5191 196.734 72.4458C196.662 72.3724 196.576 72.3141 196.481 72.2743C196.386 72.2345 196.284 72.214 196.182 72.214C196.079 72.214 195.977 72.2345 195.883 72.2743C195.788 72.3141 195.702 72.3724 195.63 72.4458C195.558 72.5191 195.502 72.6061 195.464 72.7016C195.426 72.7971 195.408 72.8992 195.41 73.002V77.7662C195.413 77.9691 195.495 78.1627 195.64 78.3052C195.784 78.4477 195.979 78.5275 196.182 78.5275C196.385 78.5275 196.579 78.4477 196.724 78.3052C196.869 78.1627 196.951 77.9691 196.954 77.7662L196.954 73.002Z" />
      <g id="signal">
        <path id="second" d="M236.909 30.5539C237.001 30.734 237.022 30.9427 236.968 31.1378C236.913 31.3329 236.786 31.5 236.613 31.6053C236.524 31.6551 236.427 31.6871 236.326 31.6992C236.226 31.7113 236.123 31.7034 236.026 31.6759C235.928 31.6484 235.837 31.6019 235.757 31.539C235.677 31.4762 235.611 31.3982 235.561 31.3095C231.86 24.9792 226.571 19.7243 220.217 16.064C213.878 12.4297 206.694 10.5261 199.386 10.5441C198.86 10.5441 198.335 10.5441 197.908 10.577C197.415 10.577 196.922 10.6099 196.396 10.6426C196.295 10.6519 196.192 10.6403 196.095 10.6085C195.997 10.5768 195.907 10.5255 195.831 10.4579C195.754 10.3903 195.691 10.3077 195.647 10.2151C195.603 10.1226 195.579 10.0222 195.575 9.91983C195.56 9.71977 195.623 9.52161 195.752 9.36798C195.881 9.21435 196.065 9.11753 196.265 9.09837C196.758 9.06548 197.284 9.06548 197.843 9.03274C198.401 9 198.927 8.99985 199.387 8.99985C206.979 9.00333 214.438 10.9949 221.021 14.7764C227.604 18.5579 233.081 23.9974 236.909 30.5537L236.909 30.5539Z" />
        <path id="first" d="M228.201 30.8497C228.263 30.9267 228.309 31.0154 228.336 31.1105C228.363 31.2056 228.371 31.3052 228.359 31.4033C228.347 31.5014 228.315 31.596 228.265 31.6815C228.215 31.767 228.149 31.8417 228.07 31.9011C227.908 32.0266 227.704 32.0832 227.501 32.0585C227.298 32.0339 227.113 31.9301 226.986 31.7697C223.798 27.4788 219.651 23.9931 214.876 21.5913C210.101 19.1894 204.83 17.938 199.485 17.9371C199.383 17.9394 199.282 17.9217 199.188 17.885C199.093 17.8482 199.007 17.7932 198.933 17.723C198.86 17.6528 198.801 17.5689 198.761 17.476C198.72 17.3831 198.698 17.2831 198.696 17.1816C198.699 16.9734 198.783 16.7745 198.93 16.6272C199.077 16.4799 199.276 16.3959 199.485 16.3931C205.066 16.4013 210.569 17.7125 215.554 20.2224C220.54 22.7322 224.87 26.3714 228.201 30.85L228.201 30.8497Z" />
        <path id="third" fill-rule="evenodd" clip-rule="evenodd" d="M221.445 7.66976C209.852 1.92269 199.402 1.62415 195.701 2.17152C195.373 2.21999 195.068 1.99355 195.019 1.66574C194.971 1.33794 195.197 1.0329 195.525 0.984425C199.472 0.400749 210.169 0.740437 221.978 6.59462C233.813 12.4619 241.97 22.9252 244.587 27.4524C244.753 27.7393 244.655 28.1063 244.368 28.2721C244.081 28.438 243.714 28.3399 243.548 28.053C241.013 23.6671 233.012 13.4038 221.445 7.66976Z" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path d="M201.456 175.516H90.2948C90.1928 175.512 90.0925 175.488 89.9997 175.446C89.9069 175.403 89.8232 175.343 89.7538 175.268C89.6843 175.193 89.6305 175.106 89.5951 175.01C89.5597 174.914 89.5434 174.812 89.5475 174.71C89.5475 174.308 89.8923 173.905 90.2948 173.905H201.456C201.664 173.92 201.861 174.009 202.009 174.157C202.157 174.305 202.246 174.502 202.261 174.71C202.256 174.923 202.17 175.125 202.02 175.275C201.87 175.425 201.668 175.511 201.456 175.516Z"/>
      <path d="M109.215 140.494C104.868 140.494 100.7 142.221 97.6261 145.294C94.5525 148.368 92.8255 152.537 92.8255 156.883C92.8255 161.23 94.5525 165.399 97.6261 168.472C100.7 171.546 104.868 173.273 109.215 173.273C118.243 173.273 125.547 165.912 125.547 156.883C125.547 147.855 118.243 140.494 109.215 140.494ZM109.215 174.825C105.667 174.825 102.198 173.773 99.2471 171.801C96.2966 169.83 93.9969 167.028 92.6389 163.749C91.2809 160.471 90.9254 156.863 91.6176 153.383C92.3099 149.903 94.0188 146.706 96.528 144.196C99.0373 141.687 102.234 139.978 105.715 139.286C109.195 138.594 112.803 138.949 116.081 140.307C119.36 141.665 122.162 143.965 124.133 146.915C126.105 149.866 127.157 153.335 127.157 156.883C127.151 161.64 125.259 166.2 121.895 169.563C118.532 172.927 113.972 174.819 109.215 174.825Z" />
      <path d="M164.709 140.494C161.467 140.494 158.299 141.455 155.603 143.256C152.908 145.057 150.808 147.617 149.567 150.612C148.327 153.606 148.002 156.902 148.635 160.081C149.267 163.26 150.828 166.181 153.12 168.473C155.413 170.765 158.333 172.325 161.512 172.958C164.691 173.59 167.987 173.266 170.981 172.025C173.976 170.785 176.536 168.684 178.337 165.989C180.137 163.293 181.099 160.125 181.099 156.883C181.091 152.539 179.362 148.375 176.29 145.303C173.217 142.231 169.053 140.501 164.709 140.494ZM164.709 174.825C161.16 174.825 157.691 173.773 154.741 171.801C151.79 169.83 149.491 167.028 148.133 163.749C146.775 160.471 146.42 156.863 147.112 153.383C147.804 149.902 149.513 146.705 152.022 144.196C154.532 141.687 157.728 139.978 161.209 139.286C164.689 138.594 168.297 138.949 171.575 140.307C174.854 141.665 177.656 143.965 179.627 146.915C181.599 149.866 182.651 153.335 182.651 156.883C182.645 161.64 180.753 166.2 177.389 169.564C174.026 172.927 169.466 174.819 164.709 174.825Z" />
      <path d="M165.112 157.516C164.958 157.51 164.809 157.464 164.678 157.384C164.547 157.303 164.439 157.19 164.364 157.056L147.515 120.654C147.473 120.56 147.451 120.458 147.448 120.355C147.446 120.252 147.464 120.149 147.502 120.053C147.539 119.957 147.595 119.869 147.666 119.795C147.737 119.72 147.823 119.661 147.917 119.619C148.012 119.578 148.113 119.555 148.216 119.553C148.319 119.55 148.422 119.569 148.518 119.606C148.614 119.643 148.702 119.699 148.776 119.77C148.851 119.842 148.911 119.927 148.952 120.021L165.802 156.366C165.975 156.769 165.802 157.286 165.457 157.458C165.348 157.505 165.23 157.525 165.112 157.516Z" />
      <path d="M126.409 121.171H110.537C110.325 121.167 110.123 121.081 109.973 120.931C109.823 120.78 109.737 120.578 109.732 120.366C109.73 120.26 109.749 120.154 109.788 120.055C109.828 119.956 109.887 119.866 109.962 119.791C110.037 119.716 110.127 119.657 110.226 119.617C110.325 119.578 110.431 119.559 110.537 119.561H126.409C126.621 119.566 126.824 119.652 126.974 119.802C127.124 119.952 127.21 120.154 127.214 120.366C127.2 120.575 127.111 120.772 126.963 120.92C126.815 121.068 126.618 121.157 126.409 121.171Z" />
      <path d="M148.032 121.114H140.441V119.561H148.032V121.114Z" />
      <path d="M137.393 156.653C137.255 156.645 137.12 156.605 137 156.535C136.88 156.465 136.778 156.367 136.703 156.251L116.173 121.114C116.09 120.926 116.073 120.716 116.125 120.517C116.177 120.318 116.295 120.143 116.461 120.021C116.648 119.934 116.86 119.915 117.059 119.968C117.259 120.02 117.433 120.141 117.553 120.309L138.083 155.445C138.313 155.79 138.141 156.308 137.795 156.538C137.67 156.603 137.534 156.642 137.393 156.653Z"/>
      <path d="M110.192 155.215H137.565L151.08 130.2H123.189L110.192 155.215ZM138.026 156.768H108.869C108.728 156.773 108.588 156.737 108.466 156.666C108.344 156.595 108.245 156.49 108.179 156.365C108.123 156.249 108.093 156.121 108.093 155.992C108.093 155.862 108.123 155.734 108.179 155.618L121.981 129.049C122.154 128.762 122.384 128.589 122.671 128.589H152.402C152.533 128.598 152.66 128.639 152.77 128.709C152.881 128.78 152.972 128.877 153.035 128.992C153.114 129.109 153.161 129.245 153.171 129.387C153.181 129.528 153.154 129.669 153.092 129.797L138.716 156.365C138.646 156.487 138.546 156.589 138.425 156.659C138.303 156.73 138.166 156.768 138.026 156.768Z" />
      <g id="road">
        <path id="third" d="M83.9697 175.516H77.5287C77.4267 175.512 77.3264 175.488 77.2336 175.446C77.1407 175.403 77.0571 175.343 76.9876 175.268C76.9182 175.193 76.8644 175.106 76.829 175.01C76.7936 174.914 76.7773 174.812 76.7814 174.71C76.7814 174.308 77.1265 173.905 77.5287 173.905H83.9697C84.1786 173.92 84.3753 174.009 84.5234 174.157C84.6714 174.305 84.7607 174.502 84.7748 174.71C84.7704 174.923 84.6842 175.125 84.5341 175.275C84.3841 175.425 84.1818 175.511 83.9697 175.516Z" />
        <path id="second" d="M70.9725 175.516H64.9914C64.885 175.518 64.7791 175.499 64.6803 175.459C64.5815 175.42 64.4918 175.36 64.4165 175.285C64.3412 175.21 64.2821 175.12 64.2425 175.021C64.2029 174.923 64.1838 174.817 64.1862 174.71C64.1906 174.498 64.2769 174.296 64.427 174.146C64.577 173.996 64.7792 173.91 64.9914 173.905H70.9725C71.1846 173.91 71.3869 173.996 71.5369 174.146C71.687 174.296 71.7732 174.498 71.7776 174.71C71.7801 174.817 71.7609 174.923 71.7214 175.021C71.6818 175.12 71.6226 175.21 71.5474 175.285C71.4721 175.36 71.3821 175.42 71.2833 175.459C71.1845 175.499 71.0789 175.518 70.9725 175.516Z" />
        <path id="first" d="M57.7865 175.516H51.8053C51.6989 175.518 51.593 175.499 51.4942 175.459C51.3954 175.42 51.3057 175.36 51.2305 175.285C51.1552 175.21 51.0961 175.12 51.0565 175.021C51.0169 174.923 50.9977 174.817 51.0002 174.71C51.0046 174.498 51.0909 174.296 51.2409 174.146C51.391 173.996 51.5932 173.91 51.8053 173.905H57.7865C57.9986 173.91 58.2008 173.996 58.3509 174.146C58.5009 174.296 58.5872 174.498 58.5916 174.71C58.5941 174.817 58.5749 174.923 58.5353 175.021C58.4957 175.12 58.4366 175.21 58.3613 175.285C58.2861 175.36 58.1961 175.42 58.0973 175.459C57.9985 175.499 57.8929 175.518 57.7865 175.516Z" />
      </g>
      <rect x="167" y="52" width="6" height="34" fill="white"/>
      <rect x="174.5" y="52" width="5" height="34" fill="white"/>
      <rect x="208" y="52" width="5" height="34" fill="white"/>
    </svg>
    <span class="sr-only" :aria-label="$t('loader.slider')"></span>
    <transition-group name="fade" mode="out-in" id="slides" tag="div">
      <p class="info" v-html="infoText" tabindex="0" :key="index" @keyup.enter="toggleSlides"></p>
    </transition-group>
  </div>
</template>

<script>

import EventBus from '@/base/event-bus'

let handler = null
export default {
  name: 'DefaultLoader',
  data () {
    return {
      animationEnabled: true,
      infoText: '',
      index: 0,
      delay: this.$VUE_LOADER_CONFIG.textRotationDelay
    }
  },
  methods: {
    setInfoText () {
      const INFO_TEXTS = [
        this.$t('loader.text2'),
        this.$t('loader.text1'),
        this.$t('loader.text3'),
        this.$t('loader.text4')
      ]
      this.index = this.index === (INFO_TEXTS.length - 1) ? 0 : this.index + 1
      this.infoText = INFO_TEXTS[this.index]
    },
    startTimer () {
      this.stopTimer()
      if (this.animationEnabled) {
        handler = setTimeout(() => {
          this.setInfoText()
          this.startTimer()
        }, this.delay)
      }
    },
    stopTimer () {
      clearTimeout(handler)
      handler = null
    },
    toggleSlides () {
      if (!handler) {
        this.startTimer()
        return
      }
      this.stopTimer()
    },
    startLoader () {
      if (!this.$VUE_LOADER_CONFIG.textRotationEnabled) {
        this.setInfoText()
        return
      }
      this.startTimer()
      this.setInfoText()
    }
  },
  mounted () {
    this.startLoader()
  },
  created () {
    EventBus.$on('language-loaded', this.startLoader)
  },
  beforeDestroy () {
    if (handler) {
      this.stopTimer()
    }
    this.animationEnabled = false
  }
}
</script>

<style lang="scss" scoped>
.fade-move {
  transition: all .5s;
}

.fade-enter-active {
  animation-name: fadeEnter;
  animation-duration: .4s;
  animation-iteration-count: 1;
}

.fade-leave-active {
  animation-name: fadeLeave;
  animation-duration: .2s;
  animation-iteration-count: 1;
  position: absolute;
}

@keyframes fadeEnter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeLeave {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
