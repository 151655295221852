<template>
  <form ref="form-usercode" @submit.prevent="submit" v-form:form novalidate>
    <click-to-pay-profile />
    <div class="form-row">
      <h3><strong>{{ $t('paywall.form.click2pay.codeForm.description') }}</strong></h3>
      <p>
        <span v-if="isActiveValidationChannelEmail()">{{ $t('paywall.form.click2pay.codeForm.codeSentEmail', {email: getValidationChannel()}) }} </span>
        <span v-if="isActiveValidationChannelPhone()">{{ $t('paywall.form.click2pay.codeForm.codeSentPhone', {phone: getValidationChannel()}) }} </span>
      </p>
    </div>
    <div class="form-row input-text-field" :class="{'is-error': isErrorForField('code')}">
      <label for="code">{{ $t('paywall.form.click2pay.codeForm.codeLabel') }}</label>
      <p role="alert" tabindex="0"
         v-if="isErrorForField('code')"
         class="error"
         :aria-label="getFieldErrorAriaLabel('code')">
        <span aria-hidden="true">{{ getFieldErrorText('code') }}</span>
      </p>
      <div class="input-text-wrapper" :class="{'is-error': isErrorForField('code')}">
        <input
          type="text"
          ref="code"
          name="code"
          id="code"
          :placeholder="$t('paywall.form.click2pay.codeForm.codePlaceholder')"
          required
          @keydown.13.prevent
          v-model.trim="formData.code" />
        <span class="input-flow-label">&nbsp;{{ $t('paywall.form.click2pay.codeForm.codeLabelFlow') }}&nbsp;</span>
        <span class="input-flow-mark"></span>
      </div>
      <p class="c2p-action" tabindex="0">
        {{ $t('paywall.form.click2pay.codeForm.codeSendLabel') }}
        <span class="sr-only"> {{ $t('paywall.form.click2pay.codeForm.codeSendAriaLabel') }}</span>
        <button type="button" @click="resendCodeSms" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonSendNewSms') }}</button> | <span class="sr-only"> {{ $t('paywall.form.click2pay.codeForm.codeSendAriaLabel2') }}</span> <button type="button" @click="resendCodeEmail" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonSendNewEmail') }}</button>
      </p>
    </div>
    <div class="agreements">
      <div class="form-row">
        <input type="checkbox"
               id="trusted"
               name="trusted"
               @keydown.13.prevent
               v-model="formData.trusted" />
        <label for="trusted">
        <span v-html="$t('paywall.form.click2pay.codeForm.trustedLabel')"></span>
        </label>
      </div>
    </div>
    <div class="submit-wrapper">
      <div class="c2p-submit">
        <button type="button" @click="goToClick2PayNewCard" class="btn btn-plain">{{ $t('paywall.form.click2pay.codeForm.buttonChangeCard') }}</button>
        <button type="submit" class="btn" :class="{'inactive': isFormInvalid()}" :disabled="processing">
          <span>{{ $t('paywall.form.click2pay.codeForm.buttonSubmit') }}</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import ClickToPayProfile from '@/base/components/Payment/Paywall/ClickToPay/Profile.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { CLICK2PAY_VIEW, CLICK2PAY_IDENTITY_TYPE, CLICK2PAY_ERROR_MESSAGES } from '@/base/const/click2pay.const'
import formMixin from '@/base/mixins/form.mixin'
import { MESSAGES } from '@/base/const/texts.const'
const maxBadCodeCounter = 3

export default {
  name: 'ClickToPayCodeForm',
  mixins: [formMixin],
  components: { ClickToPayProfile },
  data () {
    return {
      badCodeCounter: 0,
      formData: {
        code: null,
        trusted: false
      }
    }
  },
  computed: {
    ...mapState({
      processing: state => state.click2pay.processing,
      identityValidation: state => state.click2pay.identityValidation
    }),
    activeIdentityType: function () {
      return this.identityValidation.supportedValidationChannels.find(channel => channel.maskedValidationChannel === this.identityValidation.maskedValidationChannel).identityType
    }
  },
  methods: {
    ...mapMutations([
      'SET_CLICK2PAY_PROCESSING',
      'SET_CLICK2PAY_VIEW',
      'SET_CLICK2PAY_REMEMBER_ME',
      'SET_CLICK2PAY_PROCESS_RESET'
    ]),
    ...mapActions([
      'getClick2PayIdentityLookup',
      'getClick2PayInitiateIdentityValidation',
      'getClick2PayCompleteIdentityValidation',
      'addMessageSuccess',
      'addMessageError'
    ]),
    showBadCodeCounterExceededMessage () {
      const message = {
        text: MESSAGES().errors.codeCounterExceeded,
        options: {
          time: 6000
        }
      }
      this.addMessageError(message)
    },
    isBadCodeCounterExceeded () {
      return this.badCodeCounter >= maxBadCodeCounter
    },
    getValidationChannel () {
      return this.identityValidation.maskedValidationChannel
    },
    isActiveValidationChannelEmail () {
      return this.activeIdentityType === CLICK2PAY_IDENTITY_TYPE.email
    },
    isActiveValidationChannelPhone () {
      return this.activeIdentityType === CLICK2PAY_IDENTITY_TYPE.phone
    },
    isFormInvalid () {
      if (this.form) {
        return this.form.invalid
      }
      return false
    },
    goToClick2PayNewCard () {
      this.SET_CLICK2PAY_PROCESS_RESET()
      this.SET_CLICK2PAY_VIEW(CLICK2PAY_VIEW.newCard)
    },
    resendCodeSms () {
      this.resendCode(CLICK2PAY_IDENTITY_TYPE.phone)
      this.$refs['code'].focus()
    },
    resendCodeEmail () {
      this.resendCode(CLICK2PAY_IDENTITY_TYPE.email)
      this.$refs['code'].focus()
    },
    resendCode (validationChannel) {
      this.SET_CLICK2PAY_PROCESSING(true)
      this.getClick2PayInitiateIdentityValidation(validationChannel)
        .catch(() => {
          const message = {
            text: MESSAGES().errors.codeNotSent,
            options: {
              time: 2000
            }
          }
          this.addMessageError(message)
        })
        .finally(() => {
          const message = {
            text: MESSAGES().success.codeSent,
            options: {
              time: 2000
            }
          }
          this.addMessageSuccess(message)
          this.SET_CLICK2PAY_PROCESSING(false)
        })
    },
    submit () {
      if (this.isFormInvalid()) {
        return false
      }

      if (this.isBadCodeCounterExceeded()) {
        this.showBadCodeCounterExceededMessage()
        return false
      }

      this.SET_CLICK2PAY_REMEMBER_ME(this.formData.trusted)
      this.SET_CLICK2PAY_PROCESSING(true)
      this.getClick2PayCompleteIdentityValidation(this.formData.code)
        .catch(error => {
          switch (error.toString()) {
            // konto zablokowane
            case CLICK2PAY_ERROR_MESSAGES.codeAccountLocked:
              this.addErrorToField('code', 'codeAccountLocked')
              break
            // kod nieprawidłowy
            case CLICK2PAY_ERROR_MESSAGES.codeNotValid:
              this.badCodeCounter++
              this.addErrorToField('code', 'codeNotValid')
              this.SET_CLICK2PAY_PROCESSING(false)
              if (this.isBadCodeCounterExceeded()) {
                this.showBadCodeCounterExceededMessage()
              }
              break
          }
        })
        .finally(() => {
          this.SET_CLICK2PAY_PROCESSING(false)
        })
    }
  },
  mounted () {
    this.SET_CLICK2PAY_PROCESSING(false)
    this.$refs['code'].focus()
  }
}
</script>

<style lang="scss" scoped>
@import "./src/base/assets/scss/components/PaywallForm.scss";
.c2p-action {
  font-size: 12px;
  button.btn.btn-plain {
    font-size: 12px;
    text-transform: unset;
  }
}
.agreements {
  label {
    span {
      font-size: 12px;
      color: var(--color-primary);
      span {
        font-size: 14px;
        color: var(--color-text3);
      }
    }
  }
}
.submit-wrapper {
  padding-top: 10px;
  .c2p-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button.btn.btn-plain {
      font-size: 12px;
      text-transform: unset;
    }
  }
}

</style>
