<template>
  <ul class="payments"
      role="radiogroup"
      :aria-label="$t('paywall.paywayList.label')"
      aria-required="true"
      id="payway-list-radio-group">
    <li v-for="payway in filteredPayways"
        :key="payway.id"
        class="payment"
        :class="{'active': isPaywayActive(payway.id), 'inactive': !isPaywayActive(payway.id) && paywayID}">
      <payway-single :paywaysLength="filteredPayways.length" :payway="payway"/>
    </li>
    <li v-if="isManyPayways && paywayID" class="select-other">
      <button @click.prevent="selectOther">{{ $t('paywall.paywayList.linkSelectOther') }}</button>
    </li>
  </ul>
</template>

<script>
import PaywaySingle from '@/base/components/Payment/Paywall/PaywaySingle'
import { mapActions, mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { MESSAGES } from '@/base/const/texts.const'

export default {
  name: 'PaymentPaywayList',
  components: { PaywaySingle },
  props: {
    payways: {
      type: Array
    }
  },
  computed: {
    ...mapState({
      paywayID: state => state.payment.form.paywayID
    }),
    filteredPayways: function () {
      const paywayList = []
      this.payways.forEach(payway => {
        const paywayItem = ToolsClass.deepExtend(payway)
        if (!ToolsClass.isWalletPaywayAvailable(paywayItem.id)) {
          paywayItem.active = false
          if (ToolsClass.isWalletGooglePay(paywayItem.id)) {
            paywayItem.inactiveMessage = MESSAGES().errors.googlePayInactive
          }
          if (ToolsClass.isWalletApplePay(paywayItem.id)) {
            paywayItem.inactiveMessage = MESSAGES().errors.applePayInactive
          }
        }
        paywayList.push(paywayItem)
      })
      return paywayList
    },
    isManyPayways: function () {
      return this.payways.length > 1
    }
  },
  methods: {
    ...mapActions([
      'setPaymentActivePayway'
    ]),
    selectOther () {
      this.setPaymentActivePayway(null)
        .then(() => {
          setTimeout(() => {
            const focusable = [...document.querySelectorAll('.single-payway')]
            focusable[0].focus()
          })
        })
        .catch(() => {})
    },
    isPaywayActive (id) {
      return this.paywayID === id
    }
  }
}
</script>
